import { UsecasePageValues } from './usecasePageTypes';
import UsecasePage from './usecasePage';
import React from 'react';
import { AppGatsbyImageFluid } from '../../primitives/appImage';
import { graphql } from 'gatsby';
import { getFluidImage, getFluidImages } from '../../appGatsbyImageUtils';
import { getUsecaseMessage } from './usecaseMessages';
import { UsecaseTollBrothersPageQuery } from '../../appGraphQLTypes';
import { UsecaseName } from '../../appConstantsUsecase';
import useVideos from '../../queries/useVideos';
import useShoootinProjectManagers from '../../queries/useShoootinProjectManager';

const extractImages = (
  data: UsecaseTollBrothersPageQuery,
): {
  heroImage: AppGatsbyImageFluid;
  introImg: AppGatsbyImageFluid;
  step1Image: AppGatsbyImageFluid;
  step1Media: AppGatsbyImageFluid[];
  step2Image: AppGatsbyImageFluid;
} => {
  return {
    heroImage: getFluidImage(data.heroImage!, 'heroImage'),
    introImg: getFluidImage(data.introImg!, 'introImg'),
    step1Image: getFluidImage(data.step1Image!, 'step1Image'),
    step1Media: getFluidImages(data, 'step1Media'),
    step2Image: getFluidImage(data.step2Image!, 'step2Image'),
  };
};

export const useUsecaseValues = (
  data: UsecaseTollBrothersPageQuery,
): UsecasePageValues => {
  const usecase: UsecaseName = 'tollbrothers';
  const video = useVideos()[usecase];
  const pm = useShoootinProjectManagers().Laura;

  const getMessage = (category: string, key: string) =>
    getUsecaseMessage(category, key, usecase);

  const {
    heroImage,
    introImg,
    step1Image,
    step1Media,
    step2Image,
  } = extractImages(data);

  return {
    name: 'tollbrothers',
    heroImg: heroImage,
    teaserImg: step1Image,
    matterportCover: 'https://my.matterport.com/show/?m=kYDCUmXy3as&title=0',
    introImg,
    step1: {
      title: getMessage('UsecaseStep1', 'title'),
      subtitle: getMessage('UsecaseStep1', 'subtitle'),
      img: step1Image,
      media: {
        type: 'Gallery',
        gallery: step1Media,
      },
    },
    step2: {
      title: getMessage('UsecaseStep2', 'title'),
      subtitle: getMessage('UsecaseStep2', 'subtitle'),
      img: step2Image,
      quote: getMessage('UsecaseStep2', 'quote'),
      media: undefined,
    },
    step3: {
      title: getMessage('UsecaseStep3', 'title'),
      subtitle: getMessage('UsecaseStep3', 'subtitle'),
      img: video.videoImg,
      quote: getMessage('UsecaseStep3', 'quote'),
      media: {
        type: 'Video',
        videoId: video.videoUrl,
      },
    },
    project: {
      name: pm.managerName,
      img: pm.managerImg,
      title: getMessage('UsecaseProject', 'title'),
      subtitle: getMessage('UsecaseProject', 'subtitle'),
      quote: getMessage('UsecaseProject', 'quote'),
      role: getMessage('UsecaseProject', 'role'),
    },
    // outro: {
    //   img: useCasePhotoGallery(usecase, 15)[13].src,
    //   quote: getMessage('UsecaseOutro', 'quote'),
    // },
  };
};

const UsecaseTollBrothers = ({
  data,
}: {
  data: UsecaseTollBrothersPageQuery;
}) => {
  const values = useUsecaseValues(data);
  return <UsecasePage values={values} />;
};

export default UsecaseTollBrothers;

export const query = graphql`
  query UsecaseTollBrothersPage {
    heroImage: file(relativePath: { eq: "usecase_tollbrothers-header.jpg" }) {
      ...AppFluidHeroImageFragment
    }
    introImg: file(relativePath: { eq: "usecase_tollbrothers-intro.jpg" }) {
      ...UsecasePageIntroImage
    }
    step1Image: file(
      relativePath: { eq: "usecase_tollbrothers-gallery-0.jpg" }
    ) {
      ...UsecasePageStep2Image
    }
    step1Media: allFile(
      sort: { fields: name, order: ASC }
      filter: { relativePath: { glob: "usecase_tollbrothers-gallery-*.jpg" } }
    ) {
      ...UsecasePageStep1Media
    }
    step2Image: file(
      relativePath: { eq: "usecase_tollbrothers-gallery-10.jpg" }
    ) {
      ...UsecasePageStep2Image
    }
  }
`;
